
  
  .contact_us_green .responsive-container-block {
    min-height: 75px;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: auto;
    
    margin-left: auto;
  }
  
  .contact_us_green input:focus {
    outline-color: initial;
    outline-style: none;
    outline-width: initial;
  }
  
  .contact_us_green textarea:focus {
    outline-color: initial;
    outline-style: none;
    outline-width: initial;
  }
  
  .contact_us_green .text-blk {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    line-height: 25px;
  }
  
  .contact_us_green .responsive-cell-block {
    min-height: 75px;
  }
  
  .contact_us_green .responsive-container-block.container {
    max-width: 1320px;
    margin-top: 60px;
    margin-right: auto;
    margin-bottom: 60px;
    margin-left: auto;
  }
  
  .contact_us_green .responsive-container-block.big-container {
    padding-top: 0px;
    padding-right: 50px;
    padding-bottom: 0px;
    padding-left: 50px;
  }
  
  .contact_us_green .text-blk.contactus-head {
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
  }
  
  .contact_us_green .text-blk.contactus-subhead {
    max-width: 385px;
    color: #2B2D43;
    font-size: 18px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 50px;
    margin-left: 0px;
  }
  
  .contact_us_green .contact-svg {
    padding-top: 0px;
    padding-right: 25px;
    padding-bottom: 0px;
    padding-left: 0px;
    width: 65px;
    height: 40px;
  }
  
  .contact_us_green .social-media-links {
    margin-top: 80px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    width: 250px;
    display: flex;
    justify-content: space-evenly;
  }
  
  .contact_us_green .social-svg {
    width: 35px;
    height: 35px;
  }
  
  .contact_us_green .text-box {
    display: flex;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 50px;
    margin-left: 0px;
  }
  
  .contact_us_green .contact-text {
    color: #2B2D43;
  }
  
  .contact_us_green .input {
    height: 50px;
    width: 90%;
    border-top-width: 2.5px;
    border-right-width: 2.5px;
    border-bottom-width: 2.5px;
    border-left-width: 2.5px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #2B2D43;
    border-right-color: #2B2D43;
    border-bottom-color: #2B2D43;
    border-left-color: #2B2D43;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 16px;
    padding-top: 5px;
    padding-right: 15px;
    padding-bottom: 5px;
    padding-left: 15px;
  }
  
  .contact_us_green .textinput {
    height: 200px;
    width: 95%;
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #2B2D43;
    border-right-color: #2B2D43;
    border-bottom-color: #2B2D43;
    border-left-color: #2B2D43;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 16px;
    padding-top: 20px;
    padding-right: 30px;
    padding-bottom: 20px;
    padding-left: 20px;
  }
  
  .contact_us_green .submit-btn {
    min-width: 290px;
    height: 60px;
    background-color: #2B2D43;
    font-size: 18px;
    font-weight: 700;
    color: white;
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
    border-top-color: #767676;
    border-right-color: #767676;
    border-bottom-color: #767676;
    border-left-color: #767676;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    cursor: pointer;
    text-decoration: none;
    justify-self: center;
    align-self: center;
    text-align: center;
  }

.a{text-decoration: none;
}
  
  .contact_us_green .btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;

  }
  
  .contact_us_green .text-blk.input-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
  }
  
  .contact_us_green .responsive-cell-block.wk-ipadp-6.wk-tab-12.wk-mobile-12.wk-desk-6 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
  }
  
  .contact_us_green .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-5.wk-ipadp-10 {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact_us_green .head-text-box {
    display: none;
  }
  
  .contact_us_green .line {
    border-right-width: 1.8px;
    border-right-style: solid;
    border-right-color: #a2a2a2;
  }
  
  .contact_us_green .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-7.wk-ipadp-10.line {
    padding-top: 0px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  
  @media (max-width: 1024px) {
    .contact_us_green .responsive-container-block.container {
      justify-content: center;
    }
  
    .contact_us_green .text-blk.contactus-subhead {
      max-width: 90%;
    }
  
    .contact_us_green .head-text-box {
      display: block;
    }
  
    .contact_us_green .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-7.wk-ipadp-10.line {
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 60px;
      padding-left: 0px;
    }
  
    .contact_us_green .line {
      border-right-width: initial;
      border-right-style: none;
      border-right-color: initial;
      border-bottom-width: 1.8px;
      border-bottom-style: solid;
      border-bottom-color: #a2a2a2;
    }
  
    .contact_us_green .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-5.wk-ipadp-10 {
      margin-top: 60px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .contact_us_green .workik-contact-bigbox {
      display: flex;
    }
  
    .contact_us_green .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-5.wk-ipadp-10 {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  }
  
  @media (max-width: 768px) {
    .contact_us_green .text-content {
      display: none;
    }
  
    .contact_us_green .input {
      width: 100%;
    }
  
    .contact_us_green .textinput {
      width: 100%;
    }
  
    .contact_us_green .text-blk.contactus-head {
      font-size: 30px;
    }
  }
  
  @media (max-width: 500px) {
    .contact_us_green .responsive-container-block.big-container {
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
  
    .contact_us_green .workik-contact-bigbox {
      display: block;
    }
  
    .contact_us_green .text-blk.input-title {
      font-size: 16px;
    }
  
    .contact_us_green .text-blk.contactus-head {
      font-size: 26px;
    }
  
    .contact_us_green .text-blk.contactus-subhead {
      font-size: 16px;
      line-height: 23px;
    }
  
    .contact_us_green .input {
      height: 45px;
    }
  
    .contact_us_green .responsive-cell-block.wk-ipadp-6.wk-tab-12.wk-mobile-12.wk-desk-6 {
      margin: 0 0 25px 0;
    }
  }











  *,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
}

.wk-desk-1 {
  width: 8.333333%;
}

.wk-desk-2 {
  width: 16.666667%;
}

.wk-desk-3 {
  width: 25%;
}

.wk-desk-4 {
  width: 33.333333%;
}

.wk-desk-5 {
  width: 41.666667%;
}

.wk-desk-6 {
  width: 50%;
}

.wk-desk-7 {
  width: 58.333333%;
}

.wk-desk-8 {
  width: 66.666667%;
}

.wk-desk-9 {
  width: 75%;
}

.wk-desk-10 {
  width: 83.333333%;
}

.wk-desk-11 {
  width: 91.666667%;
}

.wk-desk-12 {
  width: 100%;
}

@media (max-width: 1024px) {
  .wk-ipadp-1 {
    width: 8.333333%;
  }

  .wk-ipadp-2 {
    width: 16.666667%;
  }

  .wk-ipadp-3 {
    width: 25%;
  }

  .wk-ipadp-4 {
    width: 33.333333%;
  }

  .wk-ipadp-5 {
    width: 41.666667%;
  }

  .wk-ipadp-6 {
    width: 50%;
  }

  .wk-ipadp-7 {
    width: 58.333333%;
  }

  .wk-ipadp-8 {
    width: 66.666667%;
  }

  .wk-ipadp-9 {
    width: 75%;
  }

  .wk-ipadp-10 {
    width: 83.333333%;
  }

  .wk-ipadp-11 {
    width: 91.666667%;
  }

  .wk-ipadp-12 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .wk-tab-1 {
    width: 8.333333%;
  }

  .wk-tab-2 {
    width: 16.666667%;
  }

  .wk-tab-3 {
    width: 25%;
  }

  .wk-tab-4 {
    width: 33.333333%;
  }

  .wk-tab-5 {
    width: 41.666667%;
  }

  .wk-tab-6 {
    width: 50%;
  }

  .wk-tab-7 {
    width: 58.333333%;
  }

  .wk-tab-8 {
    width: 66.666667%;
  }

  .wk-tab-9 {
    width: 75%;
  }

  .wk-tab-10 {
    width: 83.333333%;
  }

  .wk-tab-11 {
    width: 91.666667%;
  }

  .wk-tab-12 {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .wk-mobile-1 {
    width: 8.333333%;
  }

  .wk-mobile-2 {
    width: 16.666667%;
  }

  .wk-mobile-3 {
    width: 25%;
  }

  .wk-mobile-4 {
    width: 33.333333%;
  }

  .wk-mobile-5 {
    width: 41.666667%;
  }

  .wk-mobile-6 {
    width: 50%;
  }

  .wk-mobile-7 {
    width: 58.333333%;
  }

  .wk-mobile-8 {
    width: 66.666667%;
  }

  .wk-mobile-9 {
    width: 75%;
  }

  .wk-mobile-10 {
    width: 83.333333%;
  }

  .wk-mobile-11 {
    width: 91.666667%;
  }

  .wk-mobile-12 {
    width: 100%;
  }
}