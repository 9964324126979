@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic');
*{
  
    box-sizing: border-box;
}

html{
    font-family: Montserrat, sans-serif;
    color: #2B2D43;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    background-color: #EAEAEA;
}

.siteGrid{
    height: 100vh;
    display:grid;
    grid-template-rows: 120px 10fr;
}

/* Header*/
header{
    display: flex;
    width: 100%;
    height: fit-content;
    padding: 10px;
    justify-content: center;
    align-items: center;

}
header a{
    text-decoration: none;
    color:#2B2D43;
    font-size: x-large;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    cursor: pointer;
    background-color: #f1f1f1;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
.Logo{
    height: 100px;
    width: 100px;
    margin-right: 1rem;
}
.LogoRight{
    justify-self: right;
}
.Logo:hover{
    cursor: pointer;
    opacity: 0.6;
}
.title{
    margin-right: 1rem;
    font-size: larger;
}

.title:hover{
    cursor: pointer;
    opacity: 0.6;
}
.link{
    text-decoration: underline;
}
.link:hover{
    cursor: pointer;
    opacity: 0.6;
}


/* Home Main Div*/

.mainPage{
    display:flex;
    flex-direction: column;
    height: 100%;
}

.svg{
    height: 50px;
    width: 50px;
}
.maxSize{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding:5px;

}
.iframeBox{
width: clamp(100px,100%,1250px);
aspect-ratio: 16/9;
}
.iframe{
width: 100%;
height: 100%; 
border-radius: 10px;
}


/* About */
.about{
    width: 80%;
    max-width: 1250px;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.aboutT{
    align-self: flex-start;
    padding-bottom: 2rem;
    padding-left: 2rem;
 
}

.aboutCE{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 2rem;
    
}

.aboutCS{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 2rem;
    

}

.aboutHR{
    text-align: end;
    padding-bottom: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
    text-decoration: underline;
}

.aboutHL{
    align-self: flex-start;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    text-decoration: underline;
}

.aboutSvg{
    max-height: 250px;
    max-width: 250px;
}

.aboutTC {
    text-align: justify;
    align-self: center;
    padding-bottom: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
    max-width: 500px;
    
}
.Hov:hover{
    cursor: pointer;
}

/* Account */
.Centered{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    
}



@media only screen and (max-width: 500px) {
    .sm{
        display: none;
    }
    h1{
        font-size: 1.5rem
    }
    .maxSize{
        display: flex;
        width: 100%;
        align-items: start;
    }
    .iframeBox{
        margin: 5px;
        border-radius: 5px;
        padding:5px;
        width: clamp(100px,100%,1500px);
        aspect-ratio: 16/9;
    }

    .Logo{
        height: 30px;
        width: 30px;
        
    }
    .aboutSvg{
        display: none;
    }
    .siteGrid{
        height: 100vh;
        display:grid;
        grid-template-rows: 50px 10fr;
    }
}

