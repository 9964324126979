.section.cc-store-home-wrap {
    margin-right: 15px;
    margin-left: 15px;
}
@media (max-width: 767px){
.section.cc-store-home-wrap {
    margin-right: 15px;
    margin-left: 15px;
}
}
@media (max-width: 767px){
.section {
    margin-right: 15px;
    margin-left: 15px;
}
}

.intro-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 620px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-position: 0px 0px;
    background-size: cover;
    
    
}


.intro-content {
    width: 70%;
    max-width: 1140px;
    text-align: center;
}
@media (max-width: 767px){
    .intro-content {
        text-align: center;
        width: 100%;
    }
}

.intro-text {
    width: 80%;
    margin-bottom: 35px;
    text-align: center;
    align-self: center;
    justify-self: center
}
.heading-jumbo {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 64px;
    line-height: 80px;
    text-transform: none;
        
}
@media (max-width: 991px){
    .heading-jumbo {
        font-size: 56px;
        line-height: 70px;
    }
    }
    @media (max-width: 767px){
        .heading-jumbo {
            font-size: 34px;
            line-height: 64px;
        }
        }
@media (max-width: 479px){
.heading-jumbo {
    font-size: 24px;
    line-height: 48px;
}
}





@media (max-width: 767px){
.paragraph-bigger {
    font-size: 16px;
    line-height: 28px;
}}
.paragraph-bigger {
    margin-bottom: 10px;
    opacity: 1;
    font-size: 20px;
    line-height: 34px;
    font-weight: 400;

}

.button.cc-jumbo-button.cc-jumbo-white {
    color: #2B2D43;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.button.cc-jumbo-button {
    padding: 16px 35px;
    font-size: 14px;
    line-height: 26px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

@media (max-width: 991px){
.button {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}}

.button {
    padding: 12px 25px;
    border-radius: 0px;
    background-color: #2B2D43;
    -webkit-transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.w-inline-block {
    max-width: 100%;
    display: inline-block;
}


@media (max-width: 767px){
.container {
    text-align: center;
}}

.container {
    width: 100%;
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
}

.report {
    display: flex;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    justify-content: center;
}
.btn-group {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom:25px ;
}
.btn-group > a {
    margin: 10px;
    padding: 20px;
  }
.motto-wrap {
    width: 80%;
    margin-right: auto;
    margin-bottom: 85px;
    margin-left: auto;
    text-align: center;
}

.label.cc-light {
    opacity: 0.9;
}
.label {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}

@media (max-width: 767px){
.heading-jumbo-small {
    font-size: 30px;
    line-height: 52px;
}

}
.heading-jumbo-small {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 36px;
    line-height: 50px;
    font-weight: 400;
    text-transform: none;
}

.divider {
    margin-top: 50px;
    margin-bottom: 50px;
    height: 1px;
    background-color: #2B2D43;
}


.w-layout-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
}
.about-grid {
    margin-bottom: 80px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 80px;
    grid-row-gap: 30px;
    grid-template-areas: ". .";
    -ms-grid-columns: 1fr 80px 2fr;
    grid-template-columns: 1fr 2fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
.about-grid-2{
    grid-template-columns: 2fr 1fr;
    margin-bottom: 80px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 80px;
    grid-row-gap: 30px;
    grid-template-areas: ". .";
    -ms-grid-columns: 1fr 80px 2fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

@media (max-width: 991px){
    .top{
        grid-area: top;
    }
    .bottom{
        grid-area: bottom;
    }
    .about-grid {
        grid-row-gap: 50px;
        grid-template-areas:
            "."
            ".";
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        -ms-grid-rows: auto 50px auto;
        grid-template-rows: auto auto;
        text-align: center;
    }
    .about-grid-2 {
        
        grid-row-gap: 50px;
        grid-template-areas:
            "top"
            "bottom";
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        -ms-grid-rows: auto 50px auto;
        grid-template-rows: auto auto;
        text-align: center;
    }
}


.home-section-wrap {
    margin-bottom: 30px;
}

.section-heading {
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 400;
}

.paragraph-light {
    opacity: 0.9;
}




img {
    overflow-clip-margin: content-box;
    overflow: clip;
}
img {
    display: block;
}

img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}
img {
    border: 0;
}
